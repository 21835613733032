// Variables

// Colors
$white : #ffffff;
$black : #000000;
$durian : #ad976e;
$blue : #f9f9f8;
$red : #b35050;
$sha : #e5ddc7;

// mqs
$mq-1024 : '1024px';