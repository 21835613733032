// Modul Element - Video

$video-bp1: 800px;

.section-Video {
	.inner-wrapper {
		text-align: center;
		display: flex;
		.video {
			width: 100%;
		    &.youtube {
			    height: 0;
			    padding-bottom: 52%;
		    }
			iframe {
			    width: 100%;
			    height: 100%;
			    position: absolute;
		        top: 0;
    			left: 0;
			}
		}
	}
}