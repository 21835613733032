// Modul Element - SubImg

$subImg-bp1: 800px;
.section-SubImg {
	background-color: $blue;
	// margin-top: 8em;
	margin-top: 0;
	&.is-link {
		cursor: pointer;
	}
	.inner-wrapper {
		text-align: center;
		display: flex;
	    display: flex;
	    justify-content: center;
	    align-items: center;
        padding: 5em 0 6em 0;
        .image-wrapper {
		    max-width: 21.2em;
		    width: 100%;
		    @media all and (max-width: $realspecials-bp0) {
				max-width: 18em;
			}
			@media all and (max-width: $realspecials-bp2) {
				max-width: 18em;
			}
			@media all and (max-width: 600px) {
				max-width: 12.3em;
			}
        }
	}
    @media all and (max-width: $realspecials-bp0) {
    	// margin-top: 7em;
	}
	@media all and (max-width: $realspecials-bp2) {
		// margin-top: 4em;
		// margin-bottom: 4em;
	}
	@media all and (max-width: 600px) {
	 //    margin-top: 3em;
		// margin-bottom: 2em;
	}
}