// Modul Element - SubHeadline

$subHeadline-bp1: 800px;

.section-SubHeadline {
	background-color: $blue;
	@media all and (max-width: $subHeadline-bp1) {
		margin-top: 8em;
	}
	.inner-wrapper {
		text-align: center;
		display: flex;
	    display: flex;
	    justify-content: center;
	    align-items: center;
        padding: 5em 0 6em 0;
	    .sub-divider {
	    	flex: auto;
	    	background-color: $durian;
	    	height: 1px;
	    }
		.subheadline {
			@include  f_baton_b-15;
		    padding: 0 25px;
			color: $durian;
			text-transform: uppercase;
		}
	}
}