// Modul Element - Realspecials

$realspecials-bp0: 1024px;
// $realspecials-bp1: 800px;
$realspecials-bp2: 768px;

.section-Realspecials {
	background-color: $blue;
	padding: 10% 0;
	@media all and (max-width: $realspecials-bp2) {
		padding: 10% 0% 5% 0%;
	}
	.inner-wrapper {
	    display: flex;
	    flex-direction: column;
	    justify-content: center;
	    align-items: center;
	    // width: 80%;
	    // max-width: 1140px;
	    // margin: 0 auto;
		.sup-headline {
			@include f_baton_b-15;
			text-transform: uppercase;
			color: $durian;
			text-align: center;
		}
		.headline {
			@include f_beaux_r-60;
			text-transform: uppercase;
			color: $durian;
			text-align: center;
			margin-top: 20px;
		}
		.real-specials-wrapper {
			margin-top: 9.5em;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
    		@media all and (max-width: $realspecials-bp2) {
    			flex-direction: column;
    			flex-wrap: unset;
    			margin-top: 4em;
			}
			.flickity-viewport {
				width: 100%;
			}
			.flickity-page-dots {
			    position: relative;
			    bottom: 0;
			    margin-top: 5%;
			    .dot {
			    	background: transparent;
    				border: 1px solid $durian;
    				// transition
    				transition: 0.25s ease-in-out;
    				&.is-selected {
    					background: $durian;
    				}
			    }
			}
			.special-item {
			    width: 33.33%;
    			padding: 2%;
    			@media all and (max-width: $realspecials-bp2) {
					width: 100%;
					padding: 0 2%;
				}
				.text-wrapper {
				    margin-top: 2.4em;
					.title {
						@include f_beaux_r-24-2;
						text-transform: uppercase;
						color: $durian;
						text-align: center;
						@media all and (max-width: $realspecials-bp0) {
							font-size: 1.8em;
						}
						@media all and (max-width: $realspecials-bp2) {
							font-size: 2.4em;
						}
					}
					.text {
						@include f_reckless_r-16;
						color: $durian;
						text-align: center;
					    margin-top: 0.2em;
					    @media all and (max-width: $realspecials-bp0) {
							font-size: 1.4em;
						}
						@media all and (max-width: $realspecials-bp2) {
							font-size: 1.6em;
						}
					}
				}
			}
			/* enable Flickity by default */
			&:after {
				content: 'flickity';
				display: none; /* hide :after */
			}
			@media screen and ( min-width: 769px ) {
				/* disable Flickity for large devices */
				&:after {
					content: '';
				}
			}
		}
		.svg-featuredby {
			margin-top: 8em;
		    width: 21.2em;
		    height: auto;
		    @media all and (max-width: $realspecials-bp0) {
		    	margin-top: 7em;
				width: 18em;
			}
			@media all and (max-width: $realspecials-bp2) {
				width: 18em;
				margin-top: 4em;
				margin-bottom: 4em;
				// width: 12.3em;
			}
			@media all and (max-width: 600px) {
				// width: 21.2em;
				width: 12.3em;
			    margin-top: 3em;
    			margin-bottom: 2em;
			}
		}
	}
}