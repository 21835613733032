// BASE -- Navigation and Global Stuff here

body {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
body {
	color: $durian;
}
.site {
	height: auto;
	width: 100%;
	position: relative;
	z-index: 1;
}
.section {
	display: block;
	width: 100%;
	position: relative;
}


/*===============================
=            default            =
===============================*/


a {
	color: $durian;
}

svg {
    backface-visibility: hidden;
}

.arrow {
	width: 22px;
	height: 16px;

	&-right {}
	&-durian {
		polygon {
			fill: $durian;
		}
	}
	&-hover {
		transition: all 0.25s ease-in-out;
	}
	&-small {
		width: 15px;
		height: 12px;
	}
}

.arrow-hover-wrapper {
	&:hover {
		.arrow {
			&-hover {
				&-down {
					transform: rotate(90deg);
				}
				&-right {
					transform: translate(15px, 0);
				}
			}
		}
	}
}

// .width-1140 {
// 	max-width: 114em;
// 	width: 100%;
// 	margin: 0 auto;
// }
.width-1140 {
    max-width: 1140px;
    width: 90%;
	margin: 0 auto;
}
.width-980 {
	max-width: 98em;
	width: 80%;
	margin: 0 auto;
}
.width-720 {
	max-width: 72em;
	width: 90%;
	margin: 0 auto;
}
.width-580 {
	max-width: 58em;
	width: 90%;
	margin: 0 auto;
}


$from-fonts-bp1: 800px;

.button {
	@include f_baton_b-15;
	text-transform: uppercase;
    padding: 1em 3.2em 1em 3.2em;
	border: 2px solid $durian;
	display: inline-block;
	align-self: center;
	color: $durian;
	@media all and (max-width: $from-fonts-bp1) {
		font-size: 1.5em;
	}
}


.dr_gmap,#gmaps {
    width: 100%;
    height: 0;
    padding-bottom: 23%;
    min-height: 28em;
}
/*==================================
=            navigation            =
==================================*/


$nav-bp1: 1024px;
$nav-bp2: 550px;
$nav-bp3: 768px;

nav {
	width: 100%;
	position: fixed;
	top: 0;
	padding-top: 4em;
	z-index: 2;
	transition: all 0.33s ease-in-out;
	
	.nav-inner-wrapper {
	    width: 90%;
	    margin: 0 auto;
		display: flex;
	    justify-content: space-between;
        align-items: flex-start;
        transition:all 0.33s ease-in-out;
        @media all and (max-width: $nav-bp1) {
		    // justify-content: space-between;
		    // align-items: flex-start;
		}
		.box {
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 100%;
			.hamburger {
				z-index: 3;
			}
			.header-left-wrapper {
			    position: relative;
			    flex: auto;
			    height: auto;
				.header-contact-wrapper {
					width: 100%;
					position: absolute;
					left: 50%;
				    margin: 0;
				    transform: translate(-50%,0);
					opacity: 1;
					transition: all 0.33s ease-in-out;
				}
				.menu-wrapper-header {
					position: absolute;
					left: 50%;
				    transform: translate(-50%,0);
				    height: 100%;
				    margin: 0;
					opacity: 0;
					transition: all 0.33s ease-in-out;

					.menu-item-wrapper {
					    display: flex !important;
					    flex-direction: row !important;
					    align-items: center !important;
					    justify-content: center !important;
					    margin-right: 0 !important;
					    svg {
					    	margin-left: 1.2em;
					    }
					}
				}
			}
			.contact-open_times {
				@include f_beaux_r-17;
				text-transform: uppercase;
			}
			.contact-wrapper {
				margin-top: 3em;

				.contact-tel,
				.contact-address {
					@include f_baton_b-15;
					text-transform: uppercase;
				}
			}
			.logo-wrapper {
				cursor: pointer;
			    // transform: translate(-2.9em,0);
		        display: block;
    			position: absolute;
    			&-top {
    				pointer-events: auto;
    				top: 4em;
    				@media all and (max-width: $nav-bp3) {
					    top: 2em;
					}
    			}
    			&-top-not {
    				top: auto;
    				pointer-events: none;
    			}
				.logo-big {
					width: 24em;
					height: auto;
					position: relative;
					opacity: 1;
					transition: all 0.33s ease-in-out 0.33s;
					@media all and (max-width: $nav-bp1) {
					    width: 16em;
					}
					@media all and (max-width: $nav-bp3) {
					    width: 13em;
					}
				}
				.logo-small {
					width: 23em;
					height: auto;
					position: relative;
					opacity: 0;
					transition: all 0.33s ease-in-out;
					@media all and (max-width: $nav-bp1) {
						width: 16em;
					}
				}
			}
			.menu-wrapper {
			    display: flex;
			    justify-content: flex-end;
			    align-items: center;
				.menu-item-wrapper {
					cursor: pointer;
					position: relative;
					display: flex;
					flex-direction: column;
				    justify-content: center;
					align-items: center;
					.menu-item {
						@include f_baton_b-15;
						text-transform: uppercase;
						
						&-big {

						}
						&-small {
						}
					}
					&:first-child {
						margin-right: 8em;
					}
					&.hide_item {
						opacity: 0;
						pointer-events: none;
						// display: none;
					}
					.svg-arrow {
						margin-top: 2em;

					}
				}
			}
			.mobile-links {
				display: none;
				@media all and (max-width: $nav-bp1) {
					display: flex;
				}
				.mobile-link {
				    padding: 1em;
			        box-sizing: content-box;
			        @media all and (max-width: 400px) {
					}
			        polygon {
						fill: $durian;
			        }
				    &-tel {
				    	width: 18px;
				    	height: 18px;
				    }
				    &-location {
				    	width: 14px;
				    	height: 20px;
				    }
				}
			}
			&-1 {
				// margin-top: 0.5em;
				flex-direction: row;
				justify-content: flex-start;
				align-items: stretch;
				flex: 2;
				.header-left-wrapper {
					@media all and (max-width: $nav-bp1) {
				        display: none;
					}
				}
			}
			&-2 {
				align-self: center;
				flex: 1;
			    // position: absolute;
			    // transform: translate(50%,13%);
			    // left: 50%;
			    // transform: translate(-50%,0);
				// @media all and (max-width: $nav-bp1) {
			 //        align-self: center;
    // 				margin: 0 auto;
				// }
			}
			&-3 {
				// margin-top: 0.5em;
				flex: 2;
				@media all and (max-width: $nav-bp1) {
			        // margin-top: 0;
		            align-items: flex-end;
				}
				.menu-wrapper {
					@media all and (max-width: $nav-bp1) {
						display: none;
					}
				}
			}
		}
	}
	.nav-inner-wrapper {
		.box {
			.mobile-links {
				display: none;
				@media all and (max-width: $nav-bp1) {
					display: flex;
				}
			}
		}
	}
	&.is_scrolled {
		height: 9em;
		padding-top: 0;
		.nav-inner-wrapper {
			top: 0;
			left: 0;
			// position: absolute;
			background-color: $white;
			height: 9em;
			width: 100%;
			padding:0 5%;
			@media all and (max-width: $nav-bp1) {
				height: 6.5em;
			}
    		.box {
    			.logo-wrapper {
						&-top {
		    				pointer-events: none;
		    			}
		    			&-top-not {
		    				pointer-events: auto;
		    			}
						.logo-big {
							opacity: 0;
							transition: all 0.33s ease-in-out;
						}
						.logo-small {
							opacity: 1;
						}
    			}
    			.menu-wrapper {
    				.menu-item-wrapper {
						&:first-child {
							margin-right: 8em;
						}
    					.menu-item {
    					}
    					.svg-arrow {
							margin-top: 0.1em;
						}
    				}
    			}
    			.header-contact-wrapper {
				    opacity: 0;
				}
				.menu-wrapper-header {
					opacity: 1;
				}
    		}
		}
	}
}
.menu-overlay {
	position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $durian;
    z-index: 2;
    padding: 4em 0 4em 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0;
	transform: translate3d(0,-150px,0);
	pointer-events: none;
	transition: all 0.33s ease-in-out;
    @media all and (max-height: 700px) {
		padding: 2em 0 2em 0;
	}
	@media all and (max-height: 500px) {
		padding: 1em 0 1em 0;
	}
    .box {
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    .logo-wrapper {
	    	pointer-events: none;
	    }
    	.logo-big {
		    width: 24em;
		    height: auto;
	        padding: 0 0 2em 0;
	        @media all and (max-height: 700px) {
				width: 19em;
				padding-bottom: 1em;
			}
			@media all and (max-height: 500px) {
				width: 15em;
			}
			path {
				fill: $white;
			}
    	}
    	.menu-overlay-item {
    		@include f_beaux_r-60-2;
    		color: $white;
		    text-transform: uppercase;
    		@media all and (max-height: 850px) {
				font-size: 4em;
			}
			@media all and (max-height: 700px) {
				font-size: 3em;
			}
			@media all and (max-height: 500px) {
				font-size: 2em;
				line-height: 1.2em;
			}
    	}
    	.lang-switcher {
    		.lang-item {
    			@include f_beaux_r-20;
    			color: $white;
    		}
    		.lang-divider {
    			height: 2em;
    			background-color: $white;
    		}
    	}
    	&-2 {
    		flex-direction: column;
    	}
    }
    &.is-active {
		opacity: 1;
		transform: translate3d(0,0,0);
		pointer-events: auto;
		.box {
			.logo-wrapper {
		    	pointer-events: auto;
		    }
		}
	}
}
.lang-switcher {
	display: flex;
	align-items: center;
	.lang-item {
		padding: 0.8em;
		position: relative;
		&.is-active {
			// &:before {
			// 	content: "";
			// 	bottom: 0;
			// 	width: 80%;
			// 	height: 1px;
			// 	background-color: $white;
			// 	position: absolute;
			// }
			text-decoration: underline;
		}
		@media all and (max-height: 700px) {
			padding: 0.2;
		}
	}
	.lang-divider {
		width: 1px;
	}
}

$cookie-bp1: 768px;

.cookiemsg {
    position: sticky;
    bottom: 0;
    z-index: 1;
    background-color: $durian;
    // .inner-wrapper {
    	width: 90%;
    	max-width: 980px;
		padding: 10px 0 15px 18px;
		left: 4em;
		bottom: 10px;

		display: flex;
	    justify-content: space-between;
    	align-items: center;
    	opacity: 0;
    	transition: 0.33s ease-in-out;
		pointer-events: none;
		&.is_visible {
			opacity: 1;
			pointer-events: auto;
		}
		@media all and (max-width: $cookie-bp1) {
			flex-direction: column;
			width: 100%;
			max-width: unset;
			bottom: 0;
		    padding: 20px 0;
		}
    	.text {
    		@include f_reckless_r-13;
    		color: $white;
		    width: 85%;
    		max-width: 65em;
    		@media all and (max-width: $cookie-bp1) {
				text-align: center;
				line-height: 1.44em;
			}
    		a {
    			color: $white;
    		}
    	}
    	.cookie-button {
    		@include f_baton_b-15;
    		color: $white;
    		text-transform: uppercase;

    		border: 1px solid white;
		    padding: 1em 1.8em 0.7em 1.8em;
		    margin-right: 1em;
	        margin-left: 3em;
	        @media all and (max-width: $cookie-bp1) {
				width: 80%;
				margin-top: 1em;
				margin-left: 0;
				margin-right: 0;
			}
    	}
    // }
}





