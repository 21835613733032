// Modul Element - Intro

$intro-bp1: 1337px;
$intro-bp2: 1024px;
$intro-bp3: 768px;

.section-Intro {
    padding-top: 28em;
    padding-bottom: 5em;
    background-color: $blue;
    display: flex;
    flex-direction: column;
    @media all and (max-width: $intro-bp1) {
	    // padding-top: 22em;
    }
	@media all and (max-width: $intro-bp2) {
	    padding-top: 22em;
	    padding-bottom: 0;
	    height: 100vh;
	    .isIos & {
	    	height: 89vh;
	    }
    }
    @media all and (max-width: $intro-bp3) {
	    // padding-top: 19em;
	    padding-top: 13.5em;
    }
    &.hide_divider {
    	.mobile-links {
    		&:before {
    			display: none;
    		}
    	}
    }
	.inner-wrapper {
		@media all and (max-width: $intro-bp3) {
	        position: relative;
    		height: 80vh;
	    }
		.image-wrapper {
			width: 100%;
			height: 0;
		    padding-bottom: 49%;
		    position: relative;
		    @media all and (max-width: $intro-bp3) {
			    // padding-bottom: 129%;
		        padding-bottom: 0;
			    height: 100%;
			    position: absolute;
		    }
		    .slider {
		    	width: 100%;
    			height: 100%;
		    }
		    .picture-wrapper {
		    	width: 100%;
		    	height: 0;
		    	padding-bottom: 49%;
		    	position: relative;
		    	@media all and (max-width: $intro-bp3) {
		    		// padding-bottom: 102.2%;
		    		padding-bottom: 0;
		    		height: 100%;
			    }
		    }
		    img {
		    	@media all and (max-width: $intro-bp3) {
				    object-position: center right;
			    }
		    }
			.absolute-wrapper {
				position: absolute;
				right:0;
				top: 50%;
				transform: translate( 50%, -50%);

			    display: flex;
			    flex-direction: column;
			    justify-content: flex-start;
			    align-items: center;

			    @media all and (max-width: $intro-bp1) {
		    	    right: auto;
    				left: 0em;
    				transform: translate(-8em,-50%);
			    }
			    @media all and (max-width: $intro-bp2) {
		    	    right: auto;
    				left: 3em;
    				top: 3em;
    				transform: translate(-8em,0);
			    }
			    @media all and (max-width: $intro-bp3) {
    				left: 4.5em;
			    }
				.text {
					@include f_beaux_r-45;
					text-align: center;
					@media all and (max-width: $intro-bp2) {
					    font-size: 2.4em;
				    }
				}
				svg {
				    margin-top: 2.5em;
				    width: 14.5em;
				    height: auto;
				    @media all and (max-width: $intro-bp2) {
					    width: 9em;
				    }
				}
			}
			.arrow-wrapper {
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translate(-50%, -50%);
				@include f_baton_b-21;
				text-transform: uppercase;
				color: $white;
				// margin-bottom:

			    display: flex;
			    flex-direction: column;
			    justify-content: flex-start;
			    align-items: center;
			    cursor: pointer;
				.svg-scrolldown {
				    width: 30px;
    				height: 30px;
				    margin-top: 0.8em;
				    transition: all 0.25s ease-in-out;
					polygon {
						fill: $white;
					}
				}
			    &:hover {
			    	.svg-scrolldown {
			    		transform: translate(0, 15px);
			    	}
			    }
			    @media all and (max-width: $intro-bp3) {
				 	display: none;
			    }
			}
		}
	}
	.mobile-links {
		display: none;
		flex: auto;
		position: relative;
	    &:before {
	    	content: "";
	    	position: absolute;
	    	width: 1px;
	    	height: 65%;
	    	left: 50%;
	    	top: 50%;
	    	transform: translate(-50%,-50%);
	    	background-color: $durian;
	    }
		@media all and (max-width: $intro-bp2) {
		    display: block;
	    }
		.menu-wrapper {
		    display: flex;
		    justify-content: flex-end;
		    align-items: center;
		    height: 100%;
	        padding: 2em 0;
			.menu-item-wrapper {
				cursor: pointer;
				position: relative;
				flex: 1;

			    display: flex;
			    flex-direction: column;
			    justify-content: center;
			    align-items: center;
			    text-align: center;
				.menu-item {
					@include f_baton_b-15;
					text-transform: uppercase;
					
					&-big {

					}
					&-small {
						// position: absolute;
						// top: 0;
						// left: 0;
					}
				}
				.svg-arrow {
					margin-top: 2em;

				}
			}
		}
	}
}