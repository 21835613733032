// Modul Element - TextReadmore

$textReadmore-bp1: 800px;

.section-TextReadmore {
	padding: 9em 0 12em 0;
	@media all and (max-width: $textReadmore-bp1) {
    	padding: 13em 0 12em 0;
    }
	.inner-wrapper {
		text-align: center;
		display: flex;
		flex-direction: column;
		
		.sup-headline {
			@include f_baton_b-15;
			text-transform: uppercase;
		}
		.headline-big {
			@include f_beaux_r-60;
		    // margin-top: 1.3em;
		    margin-top: 0.9em;
		    &:first-child {
		    	margin-top: 0;
		    }
			text-transform: uppercase;
		}
		.text-wrapper {
		    
		}
		.headline {
			@include f_beaux_r-30;
		    margin-top: 1.8em;
			text-transform: uppercase;
			@media all and (max-width: $textReadmore-bp1) {
		    	margin-top: 1.6em;
		    }
			&:first-child {
		    	// margin-top: 0;
		    }
		}
		.text {
			@include f_reckless_r-20;
			margin-top: 1.3em;
			text-align: left;
			&:first-child {
		    	margin-top: 0;
		    }
		}
		.button {
		    margin-top: 4.2em;
		}
		.readmore {
			overflow: hidden;
			transition: all 0.5s ease-in-out;
		    height: auto;
    		position: relative;
			&.is-hidden {
				max-height:0;
			}
			.readmore-inner {
				// .text {
				// 	text-align: left;
				// }
			    // padding-top: 6em;
			}
		}
	}
}