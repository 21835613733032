// Modul Footer

$footer-bp1: 800px;
$text-bp1: 800px;

.site-footer {
	
	// background: url('../Images/pflanzen-lang.png') center 9em repeat-y;


	// background: url('../Images/pflanzen-lang.png') center top repeat-y;
	// background-size: cover;
	// background-attachment: fixed;

	
	.inner-wrapper {
		div {
			&:first-child {
				margin-top: 0;
			}

		}
		.tisch-reservieren {
			margin: 0 auto;
			text-align: center;
			width: 100%;
			position: relative;
			padding: 9em 0 10em 0;
			@media all and (max-width: $footer-bp1) {
				padding: 17em 0 8em 0;
			}
			
			.tisch-inner {
				max-width: 40em;
				width: 80%;
				margin: 0 auto;
			}
			&:before,
			&:after {
				content:"";
				position: absolute;
				top: 0;
				z-index: 1;
				pointer-events: none;
			}
			&:before {
				background: url('../Images/pflanzen_lang_links.png') top left repeat-y;
			    background-size: cover;
			    left: 0;
			    height: 100%;
		        width: 23em;
			    @media all and (max-width: $text-bp1) {
			    	background: url('../Images/pflanzen_mobile.png') center bottom repeat-x;
			    	background-size: cover;
			    	top: 0;
			    	left: 0;
			    	width: 100%;
			    	height: 13em;
			    }
			}
			&:after {
				background: url('../Images/pflanzen_lang_rechts.png') top right repeat-y;
			    background-size: cover;
				right: 0;
			    height: 100%;
			    width: 25em;
			    @media all and (max-width: $text-bp1) {
			    	display: none;
			    }
			}
			.sup-headline {
				@include f_baton_b-15;
				text-transform: uppercase;
			}
			.headline {
				@include f_beaux_r-60;
				text-transform: uppercase;
			    margin-top: 0.35em;
			}
			.divider {
				@include f_reckless_r-20;
				&-1 {
				    margin-top: 1.5em;
				}
				&-2 {
				    margin-top: 1em;
				}
			}
			.contact-info {
			    margin-top: 1em;
				.contact-item {
					@include f_beaux_r-30;
					// line-height: 1.23em;
  					letter-spacing: normal;
  					text-transform: uppercase;
  					&.mail {
  						word-break: break-word;
  					}
				}
			}
			.book-a-table {
			    margin-top: 3em;
			}
		}
		.dr_gmap,#gmaps {
		    padding-bottom: 23%;
		    // margin-top: 10em;
		}
		.newsletter {
			max-width: 78em;
			width: 90%;
			margin: 0 auto;
			display: flex;
			border: 1px solid $durian;
			margin-top: 10em;
		    padding: 4em 0;
		    position: relative;
		    @media all and (max-width: $footer-bp1) {
				flex-direction: column;
			}
		    &:before {
		    	content: "";
		    	position: absolute;
		    	width: 1px;
		    	height: 65%;
		    	left: 50%;
		    	top: 50%;
		    	transform: translate(-50%,-50%);
		    	background-color: $durian;
		    	@media all and (max-width: $footer-bp1) {
					display: none;
				}
		    }
		    .svg-logo {
		    	position: absolute;
		    	width: 90px;
			    height: 57px;
	    	    top: 0;
			    left: 50%;
			    transform: translate(-50%,-70%);
			    background-color: $white;
			    padding: 0 1em;
			    box-sizing: content-box;
		    }
			.box {
				flex: 1;
			    display: flex;
			    flex-direction: column;
			    justify-content: center;
			    align-items: center;
			    text-align: center;
				.headline {
					@include f_beaux_r-24;
					text-transform: uppercase;
					
				}
				.text {
					@include f_reckless_r-16;

				    margin-top: 1em;
					@media all and (max-width: $footer-bp1) {
						width: 90%;
					    margin: 1em auto 0 auto;
					    max-width: 21em;	
					}
				}
				.input-wrapper {
					.input-mail {
					    width: 100%;
						@include f_baton_b-15;
						line-height: 2em;
						text-transform: uppercase;
						border: 0;
						border-bottom: 1px solid $durian;
					    text-align: center;
    					padding-bottom: 0.5em;
    					border-radius: 0;
    					color: $durian;
						&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
							color: $durian;
						}
						&::-moz-placeholder { /* Firefox 19+ */
							color: $durian;
						}
						&:-ms-input-placeholder { /* IE 10+ */
							color: $durian;
						}
						&:-moz-placeholder { /* Firefox 18- */
							color: $durian;
						}
					}
					.error-email {
						@include f_baton_b-15;
						color: $red;
						max-height: 0; // 30px
						overflow: hidden;
						transition: all 0.25s ease-in-out;
						.error-email-inner {
						    padding: 0.3em;
						}
					}
					.input-datenschutz {
						display: none;
						&:checked + label {
							&:after {
								display: block;
							}
						}
					}
					.label-datenschutz {
						@include f_reckless_r-14;
						position: relative;
    					display: block;
					    padding-left: 3em;
					    a {
					    	text-decoration: underline;
					    }
						&:before,
						&:after {
							content: "";
							position: absolute;
							left: 0;
							top: 0;
						}
						&:before{
							left: 0;
							top: 0;
							width: 25px;
							height: 25px;
							border: 1px solid $durian;
						}
						&:after{
							display: none;
							left: 0;
							top: 0;
							background: url('../Svg/check.svg') center center no-repeat;
							width: 25px;
							height: 25px;
						}
					}
					&-email {
					    /* margin-top: -4em; */
					    &.has_error {
							.input-mail {
								border-bottom: 1px solid $red;
								color: $red;
								&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
									color: $red;
								}
								&::-moz-placeholder { /* Firefox 19+ */
									color: $red;
								}
								&:-ms-input-placeholder { /* IE 10+ */
									color: $red;
								}
								&:-moz-placeholder { /* Firefox 18- */
									color: $red;
								}
							}
							&.error-blocked {
								.error-email-blocked {
									max-height: 30px; // 30px
								}
							}
							&.error-exists {
								.error-email-exists {
									max-height: 30px; // 30px
								}
							}
			            }
					}
					&-datenschutz {
					    text-align: left;
				        // margin-top: 1.6em;
			            margin: 1.6em auto 0 auto;
			            width: 90%;

		                display: flex;
					    justify-content: center;
					    align-items: center;
			            &.has_error {
							.label-datenschutz {
								color: $red;
								&:before {
									border: 1px solid $red;
								}
								&:after{
									background: url('../Svg/check_red.svg') center center no-repeat;
								}
								a {
									color: $red;
								}
							}
			            }
					}
				}
				.submit {
					@include f_baton_b-15;
					line-height: 2em;
					text-transform: uppercase;
					color: $durian;
				    margin-top: 1.2em;
				    svg {
				    	margin-left: 0.6em;
				    }
				}
				&-1 {
					.nl-inner {
				    	width: 80%;
				    	margin: 0 auto;
				    }
				}
				&-2 {
					.nl-form {
					    width: 80%;
    					margin: 0 auto;
					}
					@media all and (max-width: $footer-bp1) {
						margin-top: 4em;
					}
				}
			}
		}
		$footerNew-bp1: 1000px;
		.footer {
		    display: flex;
	        align-items: center;
            justify-content: space-between;
	        margin: 0 auto;
            width: 80%;
    		margin: 5em auto 0 auto;
    		position: relative;
    		@media all and (max-width: $footerNew-bp1) {
			   	flex-direction: column;
		   	    width: 100%;
		   	    max-width: 550px;
			}
			.box {
				flex: 1;
				.open-times {
					@include f_beaux_r-17;
					text-transform: uppercase;
					
				}
				.social-icons {
				    display: flex;
				    justify-content: flex-start;
				    align-items: center;
		            margin-top: 1.5em;
				    .social-link {
			    	    padding: 1em;
			    	  //   .svg-tripadvisor {
		    	   //  	    width: 26px;
    						// height: 16.25px;
			    	  //   }
				    }
				}
				.further_links {
				    width: 100%;
				    display: flex;
				    flex-direction: column;
					text-align: center;
					.further-link {
						@include f_baton_b-15;
						line-height: 2em;
						text-transform: uppercase;
						// &:nth-child(1) {
						//     margin-right: 3%;
						// }
						// &:nth-child(2) {
						//     margin-left: 3%;
						// }
					}
				}
				.lang-switcher {
					.lang-item {
						@include f_beaux_r-17;
						color: $durian;
					}
					.lang-divider {
						background-color: $durian;
						height: 1.7em;
					}
				}
				.image-footer {
					height: auto;
					position: absolute;
					pointer-events: none;
					&-1 {
						width: 30em;
						@media all and (max-width: $footerNew-bp1) {
						    left: 50%;
						    transform: translate(-50%, 0);
						    top: 8em;
						}
					}
					&-2 {
						width: 8.2em;
					    top: -7em;
    					left: -12em;
    					@media all and (max-width: $footerNew-bp1) {
						    left: 10%;
    						top: -2em;
						}
					}
					&-3 {
						width: 8.2em;
					    right: -16%;
    					top: 3em;
    					@media all and (max-width: 1230px) {
						    opacity: 0;
						}
						@media all and (max-width: $footerNew-bp1) {
						    right: 0;
						    opacity: 1;
						}
					}
				}
				&-1 {
					// margin-top: 5em;
					@media all and (max-width: $footerNew-bp1) {
					    order: 1;
					    width: 100%;
					}
					.box-inner {
					    display: flex;
					    flex-direction: column;
					    justify-content: flex-start;
					    align-items: center;
					}
				}
				&-2 {
    				// display: flex;
    				align-self: flex-start;
    				position: relative;
				    // justify-content: center;
					@media all and (max-width: $footerNew-bp1) {
				        order: 3;
					    width: 100%;
					    height: auto;
					    // margin-top: 8em;
					}
					// svg {
					// 	width: 240px;
					// 	height: auto;
					//     transform: translate(0, 0);
					//     @media all and (max-width: $footerNew-bp1) {
					//     	transform: translate(0, 0);
					// 	}
					// }
				}
				&-3 {
				    display: flex;
				    flex-direction: column;
				    justify-content: center;
				    align-items: center;
				    // margin-top: 5em;
					@media all and (max-width: $footerNew-bp1) {
			            order: 2;
					    margin-top: 6.5em;
					}
					.contact-info {
					    display: flex;
					    flex-direction: column;
					    justify-content: center;
					    align-items: center;
					    margin-top: 2em;
						.contact-item {
							@include f_baton_b-15;
							text-transform: uppercase;
							
						}
					}
				}
			}
		}
	} 
}