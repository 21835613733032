// Modul Element - Text

$text-bp1: 800px;

.section-Text {
	padding: 9em 0 12em 0;
	// background: url('../Images/pflanzen_kurz.png') center center repeat-y;
	// background-size: cover;
	// background-attachment: fixed;

	&:before,
	&:after {
		content:"";
		position: absolute;
		top: 0;
	}
	&:before {
		background: url('../Images/pflanzen_kurz_links.png') center center repeat-y;
	    background-size: cover;
	    left: 0;
	    height: 100%;
	    width: 28em;
	    @media all and (max-width: $text-bp1) {
	    	background: url('../Images/pflanzen_mobile.png') center bottom repeat-x;
	    	background-size: cover;
	    	top: 0;
	    	left: 0;
	    	width: 100%;
	    	height: 13em;
	    }
	}
	&:after {
		background: url('../Images/pflanzen_kurz_rechts.png') center center repeat-y;
	    background-size: cover;
		right: 0;
	    height: 100%;
	    width: 16em;
	    @media all and (max-width: $text-bp1) {
	    	display: none;
	    }
	}
	@media all and (max-width: $text-bp1) {
    	padding: 13em 0 0 0;
    }
    &.no_plants {
    	margin-top: 20em;
    	padding: 9em 0 12em 0;
    	&:before,
		&:after {
			display: none;
		}
		@media all and (max-width: 1024px) {
	    	margin-top: 13em;
	    }
		@media all and (max-width: 768px) {
	    	margin-top: 3em;
	    	padding: 13em 0 0 0;
	    }
	    @media all and (max-width: 500px) {
	    	margin-top: 3em;
	    }
    }
	.inner-wrapper {
		text-align: center;
		display: flex;
		flex-direction: column;
		
		.sup-headline {
			@include f_baton_b-15;
			text-transform: uppercase;
		}
		.headline {
			@include f_beaux_r-60;
			margin-top: 0.4em;
			text-transform: uppercase;
		}
		.text {
			@include f_reckless_r-20;
			margin-top: 2.5em;
		}
		.button {
		    margin-top: 4.2em;
		}
	}
	.blocks {
		width: 100%;
	    display: flex;
	    justify-content: space-around;
	    margin-top: 10em;
	    @media all and (max-width: $text-bp1) {
	    	display: none;
	    }
	    .block-item {
	    	@include f_beaux_r-32;
	    	text-transform: uppercase;
	    	flex: 1;
	    	text-align: center;
	    	position: relative;
    	    display: flex;
		    justify-content: center;
		    align-items: center;
	    	&:after {
	    		content:"";
				position: absolute;
				width: 1px;
				height: 90px;
				top: 50%;
				right: 0;
				left: auto;
				background-color: $durian;
				transform: translate(0,-50%);
	    	}
	    }
	}
}