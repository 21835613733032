// Modul Element - Specials

$specials-bp1: 1024px;

.section-Specials {
    margin-top: 5em;
    overflow: hidden;
	.inner-wrapper {
		.special-item {
			display: flex;
			min-height: 57em;
			&:nth-child(2n+1) {
				.box-1 {
					order: 1;
					@media all and (max-width:$specials-bp1) {
				    	padding: 50px 0 0 0;
					}
				}
				.box-2 {
					order: 2;
				    // margin: 0 10%;
				    .image-wrapper {
			    	    // margin: 10%;
			    	    margin: 50px 10%;
			    	    height: 80%;
				    }
				}
			}&:nth-child(2n) {
				.box-1 {
					order: 2;
					background-color: $blue;
					@media all and (max-width:$specials-bp1) {
						order: 1;
				    	padding: 50px 0 50px 0;
					}
				}
				.box-2 {
					order: 1;
					@media all and (max-width:$specials-bp1) {
						order: 2;
					}
				}
			}
			@media all and (max-width:$specials-bp1) {
				flex-direction: column;
				min-height: unset;
			}
			.box {
				flex: 1;
			    display: flex;
			    justify-content: center;
			    align-items: center;
		        // align-items: stretch;
			    text-align: center;
			    // &-1 {
		    	//     margin-bottom: 5em;
			    // }
				.image-wrapper {
				    width: 100%;
				    height: 100%;
				    // padding-bottom: 79%;
				    @media all and (max-width:$specials-bp1) {
					    height: 0;
						padding-bottom: 63%;
					}
				}
				.text-wrapper {
				    max-width: 420px;
    				width: 80%;
					.sup-headline {
						@include f_baton_b-15;
						text-transform: uppercase;
						
					}
					.headline {
						@include f_beaux_r-60;
						margin-top: 0.3em;
					}
					.slider-specials {
						.slider-item {
							width: 100%;
						    margin: 0 5%;
						}
						.flickity-button {
							background: transparent;
						}
						.flickity-button.previous {
						    transform: translate(-5em,0);
						}
						.flickity-button.next {
						    transform: translate(5em,0);
						}
						.flickity-button-icon {
							fill: $durian;

						}
					}
					.text {
						@include f_reckless_r-20;
						width: 90%;
					    margin-top: 1.4em;
					    margin:1.4em 5% 0 5%;
					}
					.button {
					    margin-top: 2em;
					}
				}
			}
		}
	}
}