// Modul Element - Mittagstisch

$mittagstisch-bp1: 1024px;
$mittagstisch-bp2: 768px;

.section-Mittagstisch {
	padding: 9em 0 12.5em 0;
	background-color: $blue;
	@media all and (max-width: $mittagstisch-bp2) {
		padding: 9em 0 9em 0;	
	}
	.inner-wrapper {
	    display: flex;
    	flex-direction: column;
		.text-wrapper {
			text-align: center;
			@media all and (max-width: $mittagstisch-bp2) {
				max-width: 50em;
			}
			.sup-headline {
				@include f_baton_b-15;
				text-transform: uppercase;
			}
			.headline {
				@include f_beaux_r-60;
				text-transform: uppercase;
				margin-top: 0.3em;
				
			}
			.times {
				@include f_beaux_r-30;
			    margin-top: 2em;
			    br {
			    	display: none;
			    	@media all and (max-width: $mittagstisch-bp2) {
						display: block;
					}
			    }
			}
			.info {
				@include f_reckless_r-20;
			    margin-top: 0.25em;
			    @media all and (max-width: $from-fonts-bp1) {
					font-size: 1.8em;
				}
			}
			.courses {
				@include f_reckless_r-24;
			    margin-top: 1.3em;
			    .course-item-salat {
			    	margin-top: 1.3em;
					
			    }
			    br {
			    	display: none;
			    	@media all and (max-width: $mittagstisch-bp2) {
						display: block;
					}
			    }
			    .hide_mobile {
			    	@media all and (max-width: $mittagstisch-bp2) {
						display: none;
					}
			    }
			}
			.wochensalat {
				// @include f_reckless_r-24;
				// @include f_reckless_r-20;
				margin-top: 0.25em;

				font-family: $baton;
				font-size: 1.8em;
				font-weight: 600;
				font-style: normal;
				line-height: 1.11em;
				letter-spacing: 1.2px;
				text-transform: uppercase;
				@media all and (max-width: $from-fonts-bp1) {
					// font-size: 1.8em;
				}
			}
		}
		.hand {
		    position: absolute;
		    right: 0;
		    width: 34em;
		    top: 22em;
	        transform: rotateY(180deg);
		    @media all and (max-width: 1100px) {
				width: 28em;
			}
			@media all and (max-width: $mittagstisch-bp1) {
				width: 26em;
			}
			@media all and (max-width: 900px) {
				width: 18em;
			}
			@media all and (max-width: $mittagstisch-bp2) {
				position: relative;
				width: 23em;
				margin-top: 2em;
				top: 0;
				right: auto;
				// margin-top: 2em;
			    align-self: flex-end;
			}
		}
		.menu-wrapper {
			width: 90%;
			max-width: 1140px;
			margin: 0 auto;
			display: flex;
			flex-wrap: wrap;
			margin-top: 2.5em;
			// @media all and (max-width: $mittagstisch-bp2) {
				// margin-top: 2em;
			// }
			.menu-item {
				width: 33%;
				margin-top: 8em;
			    padding-right: 5%;
			    @media all and (max-width: $mittagstisch-bp1) {
					width: 50%;
				}
				@media all and (max-width: $mittagstisch-bp2) {
					width: 100%;
					margin-top: 5em;
					padding-right: 0;
					&:first-child {
						margin-top: 0;
					}
				}
				.headline-wrapper {
					@include f_beaux_r-30;
					text-transform: uppercase;
					display: flex;
					align-items: center;
					position: relative;
					&:after {
						content: "";
						flex: auto;
						margin-left: 3%;
						height: 1px;
						background-color: $durian;
						// @media all and (max-width: $mittagstisch-bp2) {
						// 	position: absolute;
						// 	left: 0;
						// 	margin-left: 0;
						// 	top: 50%;
						// 	flex: unset;
						// 	width: 100%;
						// 	transform: translate(0, -50%);
						// }
					}
					&:before {
						content: "";
						flex: auto;
						margin-right: 3%;
						height: 1px;
						background-color: $durian;
						display: none;
					}
					 @media all and (max-width: $mittagstisch-bp2) {
						&:before {
							display: block;
						}
					}
				}
				.text {
					@include f_reckless_r-20;
					line-height: 1.15em;
				    margin-top: 1.5em;
				    @media all and (max-width: $mittagstisch-bp2) {
						text-align: center;
					}
					p {
						margin-top: 0.8em;
						@media all and (max-width: $mittagstisch-bp2) {
							margin-top: 0.4em;
						}
					}
					strong {
						font-family: $baton;
						font-size: .9em;
						font-weight: 600;
						font-style: normal;
						line-height: 1.11em;
						letter-spacing: 1.2px;
						text-transform: uppercase;
						
					}
				}
			}
			.chicken-wrapper {
				width: 33%;
				margin-top: 12em;
			    padding-right: 5%;
		        display: flex;
				justify-content: center;
    			align-items: center;
    			&.has_text {
    				flex-direction: column;
    				justify-content: flex-start;
    				margin-top: 5.4em;
    				border: 1px solid $durian;
    				// padding: 2.5em 0 2.5em 0;
    				// padding: 2.5em 3%;
				    padding: 2.5em 4% 3em 4%;

				    @media all and (max-width: $mittagstisch-bp2) {
						display: block !important;
					}
    			}
			    @media all and (max-width: $mittagstisch-bp1) {
					width: 50%;
				}
				@media all and (max-width: $mittagstisch-bp2) {
					width: 100%;
					margin-top: 5em;
					padding-right: 0;

					display: none;
				}
				.chicken-headline {
					@include f_beaux_r-30;
					display: flex;
					text-align: center;
				    justify-content: center;
				}
				.chicken-speisen {
					width: 100%;
				    // margin-top: 1.5em;
				    // max-width: 42em;
			        margin: 1.5em auto 0 auto;
					.chicken-speise {
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						justify-content: space-around;
						margin-top: 1.2em;
						.chicken-speise-text {
							flex: 1;
							// @include f_reckless_r-20;
							// line-height: 1.15em;

							font-family: $baton;
							font-size: 1.8em;
							font-weight: 600;
							font-style: normal;
							line-height: 1.11em;
							letter-spacing: 1.2px;
							text-transform: uppercase;
						}
						.chicken-speise-price {
							@include f_reckless_r-20;
							font-size: 1.8em;
							line-height: 1.15em;
						    margin-bottom: 0.17em;
						}
						.chicken-speise-subtext {
							flex: 1 100%;
							@include f_reckless_r-20;
							font-size: 1.8em;
							line-height: 1.15em;
						}
					}
				}
				.chicken-infos {
					@include f_reckless_r-20;
					line-height: 1.15em;
					font-size: 1.8em;
					text-align: center;
				    margin-top: 1.5em;
					border-top: 1px solid $durian;
				    padding-top: 0.8em;
				}
			}
		}
	}
}