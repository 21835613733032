@font-face {
  font-family: 'Baton';
  font-weight:400;
  font-style: normal;
  font-display: swap;
  text-rendering: optimizeLegibility;
  src: url('../Fonts/BatonTurboWeb-Regular.eot'); /* IE9 Compat Modes */
  src: url('../Fonts/BatonTurboWeb-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../Fonts/BatonTurboWeb-Regular.woff') format('woff'); /* Pretty Modern Browsers */
}
@font-face {
  font-family: 'Baton';
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  text-rendering: optimizeLegibility;
  src: url('../Fonts/BatonTurboWeb-RegularItalic.eot'); /* IE9 Compat Modes */
  src: url('../Fonts/BatonTurboWeb-RegularItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../Fonts/BatonTurboWeb-RegularItalic.woff') format('woff'); /* Pretty Modern Browsers */
}

@font-face {
  font-family: 'Baton';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  text-rendering: optimizeLegibility;
  src: url('../Fonts/Baton-Bold-Web.eot'); /* IE9 Compat Modes */
  src: url('../Fonts/Baton-Bold-Web.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../Fonts/Baton-Bold-Web.woff') format('woff'); /* Pretty Modern Browsers */
}

@font-face {
    font-family: 'BEAUXDisplay';
    src: url('../Fonts/BEAUX-Display.eot'); /* IE9 Compat Modes */
    src: url('../Fonts/BEAUX-Display.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../Fonts/BEAUX-Display.woff') format('woff'), /* Modern Browsers */
         url('../Fonts/BEAUX-Display.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../Fonts/BEAUX-Display.svg#BEAUX-Display') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-display: swap;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'RecklessNeue-Book';
    src: url('../Fonts/RecklessNeue-Book.eot'); /* IE9 Compat Modes */
    src: url('../Fonts/RecklessNeue-Book.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../Fonts/RecklessNeue-Book.woff') format('woff'), /* Modern Browsers */
         url('../Fonts/RecklessNeue-Book.woff2') format('woff2'); /* Modern Browsers */
    font-style: normal;
    font-display: swap;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}


$beaux: 'BEAUXDisplay', sans-serif;
$baton: 'Baton', sans-serif;
$reckless: 'RecklessNeue-Book', sans-serif;

/*==========================
=            bp            =
==========================*/

$fonts-bp1: 800px;


/*=====================================
=            beaux regular            =
======================================*/


@mixin f_beaux_r-17 {
	font-family: $beaux;
	font-size: 1.7em;
	font-weight: 400;
	font-style: normal;
	line-height: normal;
	@media all and (max-width:$mq-1024) {
		// font-size:1.9em;
	}
}

@mixin f_beaux_r-20 {
	font-family: $beaux;
	font-size: 2em;
	font-weight: 400;
	font-style: normal;
	line-height: normal;
	@media all and (max-width:$mq-1024) {
		// font-size:1.9em;
	}
}

@mixin f_beaux_r-24 {
	font-family: $beaux;
	font-size: 2.4em;
	font-weight: 400;
	font-style: normal;
	line-height: normal;
	letter-spacing: -1px;
	@media all and (max-width:$mq-1024) {
		// font-size:1.9em;
	}
}

@mixin f_beaux_r-24-2 {
	font-family: $beaux;
	font-size: 2.4em;
	font-weight: 400;
	font-style: normal;
	line-height: 1.33em;
	letter-spacing: 0.4px;
	@media all and (max-width:$mq-1024) {
		// font-size:1.9em;
	}
}

@mixin f_beaux_r-30 {
	font-family: $beaux;
	font-size: 3em;
	font-weight: 400;
	font-style: normal;
	// line-height: 1.1em;
	line-height: 1.2em;
	letter-spacing: normal;
	@media all and (max-width: $fonts-bp1) {
		font-size: 2.7em;
	}
}

@mixin f_beaux_r-32 {
	font-family: $beaux;
	font-size: 3.2em;
	font-weight: 400;
	font-style: normal;
	// line-height: 1.1em;
	line-height: 1.22em;
	letter-spacing: -0.7px;
	@media all and (max-width: $fonts-bp1) {
		font-size: 2.7em;
	}
}

@mixin f_beaux_r-45 {
	font-family: $beaux;
	font-size: 4.5em;
	font-weight: 400;
	font-style: normal;
	line-height: 1.2em;
	// letter-spacing: -2.57px;
	letter-spacing: -1.5px;
	@media all and (max-width:$mq-1024) {
		// font-size:1.9em;
	}
}

@mixin f_beaux_r-60 {
	font-family: $beaux;
	font-size: 6em;
	font-weight: 400;
	font-style: normal;
	line-height: 1.17em;
	letter-spacing: normal;
	@media all and (max-width: $fonts-bp1) {
		font-size: 4em;
	}
}

@mixin f_beaux_r-60-2 {
	font-family: $beaux;
	font-size: 6em;
	font-weight: 400;
	font-style: normal;
	line-height: 1.48em;
	letter-spacing: 1px;
	@media all and (max-width: $fonts-bp1) {
		font-size: 4em;
	}
}


/*==================================
=            baton bold            =
==================================*/

@mixin f_baton_b-15 {
	font-family: $baton;
	font-size: 1.5em;
	font-weight: 600;
	font-style: normal;
	line-height: 1.33em;
	letter-spacing: 1px;
	@media all and (max-width: $fonts-bp1) {
		font-size: 1.3em;
	}
}

@mixin f_baton_b-21 {
	font-family: $baton;
	font-size: 2.1em;
	font-weight: 600;
	font-style: normal;
	line-height: 0.95em;
  	letter-spacing: 1.4px;
	@media all and (max-width:$mq-1024) {
		// font-size:1.9em;
	}
}


/*================================
=            reckless            =
================================*/

@mixin f_reckless_r-13 {
	font-family: $reckless;
	font-size: 1.3em;
	line-height: 1.23em;
  	letter-spacing: normal;
	@media all and (max-width:$mq-1024) {
		// font-size:1.9em;
	}
}

@mixin f_reckless_r-14 {
	font-family: $reckless;
	font-size: 1.4em;
	line-height: 1.07em;
  	letter-spacing: normal;
	@media all and (max-width:$mq-1024) {
		// font-size:1.9em;
	}
}

@mixin f_reckless_r-16 {
	font-family: $reckless;
	font-size: 1.6em;
	line-height: 1.5em;
  	letter-spacing: normal;
	@media all and (max-width:$mq-1024) {
		// font-size:1.9em;
	}
}

@mixin f_reckless_r-20 {
	font-family: $reckless;
	font-size: 2em;
	line-height: 1.45em;
  	letter-spacing: normal;
	@media all and (max-width: $fonts-bp1) {
		font-size: 1.5em;
	}
}

@mixin f_reckless_r-24 {
	font-family: $reckless;
	font-size: 2.4em;
	line-height: 1.25em;
  	letter-spacing: normal;
	@media all and (max-width: $fonts-bp1) {
		font-size: 2.1em;
	}
}

