// Modul Element - ImgSlider

$imgSlider-bp1: 1024px;
$imgSlider-bp2: 768px;
// $imgSlider-bp1: 800px;

.section-ImgSlider {
    padding: 10%;
    background-color: $sha;
    @media all and (max-width: $imgSlider-bp2) {
		padding: 10% 10% 5% 10%;
	}
	.inner-wrapper {
		.slider {
			width: 100%;
			.slider-item {
				width: 100%;
				// buttons fill : $durian;
			}
			.flickity-page-dots {
			    position: relative;
			    bottom: 0;
			    margin-top: 5%;
			    .dot {
			    	background: transparent;
    				border: 1px solid $durian;
    				// transition
    				transition: 0.25s ease-in-out;
    				&.is-selected {
    					background: $durian;
    				}
			    }
			}
			.flickity-button {
				background: transparent;
				.flickity-button-icon {
					fill: $durian;
				}
				&.previous {
					transform: translate(-8em, 0);
					@media all and (max-width: $imgSlider-bp1) {
						transform: translate(-6.2em, 0);
					}
				}
				&.next {
					transform: translate(8em, 0);
					@media all and (max-width: $imgSlider-bp1) {
						transform: translate(6.2em, 0);
						
					}
				}
			}
		}
	}
}